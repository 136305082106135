<template>
  <!-- Error page-->
  <div class="misc-wrapper">

    <!-- Brand logo-->
    <b-link class="brand-logo">
        <h1 class="brand-text text-primary ml-1">
          <b-img
              :src="appLogoImage"
              alt="logo"
            />
          <!--AVENIR-->
        </h1>
      </b-link>
      <!-- /Brand logo-->

    <div class="misc-inner p-2 p-sm-3">
      <div class="w-100 text-center">
        <h2 class="mb-1">
          Usuario no autorizado
        </h2>
        <p class="mb-2">
          El usuario no está autorizado para acceder al recurso solicitado
        </p>

        <b-button
          variant="primary"
          class="mb-2 btn-sm-block"
          :to="{path:'/'}"
        >
          Volver al inicio
        </b-button>

      </div>
    </div>
  </div>
<!-- / Error page-->
</template>

<script>
/* eslint-disable global-require */
import { BLink, BButton, BImg } from 'bootstrap-vue'
import store from '@/store/index'

export default {
  components: {
    BLink,
    BButton,
    BImg,
  },
  data() {
    return {
      appLogoImage: require('@/assets/images/logo/logo-login.png'),
      downImg: require('@/assets/images/pages/error.svg'),
    }
  },
  computed: {
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.downImg = require('@/assets/images/pages/error-dark.svg')
        return this.downImg
      }
      return this.downImg
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-misc.scss';

.brand-text {
    color: #0F6E7C;
    font-weight: 600;
    letter-spacing: 0.01rem;
    font-size: 1.6rem;
    padding: 10px;
}
</style>
